.component-stats-and-image {
  --gap: 2.5rem;
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;
  font-size: var(--font-size-h4-a);
  font-weight: 600;
  line-height: 1.3;
}
@media (max-width: 1550px) {
  .component-stats-and-image {
    font-size: calc(var(--font-size-h4-a) * 0.8);
  }
}
@media (max-width: 1380px) {
  .component-stats-and-image {
    --gap: 1.7rem;
    font-size: calc(var(--font-size-h4-a) * 0.7);
  }
}
.component-stats-and-image__stats-wrapper {
  flex: 1;
  background: var(--color-blue-dark);
  color: #fff;
  padding: 2.875rem 3.5rem 2rem;
  padding-right: 0;
  max-width: calc(50% - var(--gap));
}
@media (max-width: 1380px) {
  .component-stats-and-image__stats-wrapper {
    padding: 2.4rem 2.8rem 1.8rem;
  }
}
@media (max-width: 1100px) {
  .component-stats-and-image__stats-wrapper {
    padding: 2rem 2.4rem 1.6rem;
  }
}
.component-stats-and-image__stat-single {
  padding: 0 0 1.6rem;
}
.component-stats-and-image__stat-single:last-child {
  padding-bottom: 0;
}
.component-stats-and-image__headline {
  color: var(--color-blue-light);
}
.component-stats-and-image__stat {
  font-size: calc(var(--font-size-h2) * 1.08);
  font-weight: 500;
}
@media (max-width: 1380px) {
  .component-stats-and-image__stat {
    font-size: calc(var(--font-size-h2) * 1);
  }
}
@media (max-width: 1100px) {
  .component-stats-and-image__stat {
    font-size: calc(var(--font-size-h2) * 0.8);
  }
}
.component-stats-and-image__image-wrapper {
  flex: 1;
  max-width: calc(50% - var(--gap));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: auto calc();
}
.component-stats-and-image__image-wrapper img {
  object-fit: cover;
  flex: 1;
}
@media (max-width: 500px) {
  .component-stats-and-image__image-wrapper,
  .component-stats-and-image__stats-wrapper {
    min-width: 100%;
  }
}
.component-stats-and-image--with-link .component-stats-and-image__image-wrapper {
  cursor: pointer;
}
.component-stats-and-image--with-link .component-stats-and-image__image-wrapper:hover a {
  color: #fff;
}
.component-stats-and-image__image-text {
  text-align: center;
  height: calc(var(--font-size-h4-a) * 2.8);
  line-height: calc(var(--font-size-h4-a) * 2.8);
  background: var(--color-blue-light);
  padding: 0 0.5rem;
}
.component-stats-and-image__image-text a {
  text-decoration: none;
}
