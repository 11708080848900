.component-posts-grid {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  --post-width-half: 50%;
  --post-width-one-third: calc(100% / 3);
  --post-height-half: 500px;
  --post-height-one-third: 333px;
}
@media (max-width: 650px) {
  .component-posts-grid {
    --post-width-half: calc(100% / 3 * 2);
  }
}
@media (max-width: 550px) {
  .component-posts-grid {
    --post-width-half: 100%;
  }
}
@media (max-width: 950px) {
  .component-posts-grid {
    --post-width-one-third: 50%;
  }
}
@media (max-width: 550px) {
  .component-posts-grid {
    --post-width-one-third: 100%;
  }
}
@media (max-width: 1200px) {
  .component-posts-grid {
    --post-height-half: 480px;
  }
}
@media (max-width: 1000px) {
  .component-posts-grid {
    --post-height-half: 460px;
  }
}
@media (max-width: 800px) {
  .component-posts-grid {
    --post-height-half: 420px;
  }
}
@media (max-width: 600px) {
  .component-posts-grid {
    --post-height-half: 380px;
  }
}
@media (max-width: 1200px) {
  .component-posts-grid {
    --post-height-one-third: 320px;
  }
}
@media (max-width: 1000px) {
  .component-posts-grid {
    --post-height-one-third: 300px;
  }
}
@media (max-width: 800px) {
  .component-posts-grid {
    --post-height-one-third: 280px;
  }
}
@media (max-width: 600px) {
  .component-posts-grid {
    --post-height-one-third: 255px;
  }
}
.component-posts-grid__inner {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
@media (max-width: 1200px) {
  .component-posts-grid__inner {
    padding-top: 2.7rem;
    padding-bottom: 1.8rem;
  }
}
@media (max-width: 1000px) {
  .component-posts-grid__inner {
    padding-top: 2.4rem;
    padding-bottom: 1.6rem;
  }
}
@media (max-width: 800px) {
  .component-posts-grid__inner {
    padding-top: 1.95rem;
    padding-bottom: 1.3rem;
  }
}
.component-posts-grid__posts {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.component-posts-grid__post {
  flex: 1;
  min-width: var(--post-width);
  min-height: var(--post-height);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 2rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.component-posts-grid__post:after {
  content: " ";
  position: absolute;
  background: rgba(0,0,0,0.3);
  background-blend-mode: multiply;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.component-posts-grid__post-content {
  z-index: 1;
  position: relative;
}
.component-posts-grid h3 {
  font-size: var(--font-size-h3-b);
  font-weight: 700;
}
.component-posts-grid h3 a {
  text-decoration: none;
}
.component-posts-grid__post-excerpt {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.component-posts-grid__post-button {
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  border-radius: 10rem;
  border: 1px solid;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 4px;
  color: #fff;
}
@media (max-width: 1200px) {
  .component-posts-grid__post-button {
    height: 38px;
    line-height: 37px;
  }
}
@media (max-width: 1000px) {
  .component-posts-grid__post-button {
    height: 35px;
    line-height: 34px;
    padding: 0 18px;
  }
}
@media (max-width: 800px) {
  .component-posts-grid__post-button {
    height: 32px;
    line-height: 30px;
    padding: 0 16px;
  }
}
.component-posts-grid__post-button[target=_blank] {
  position: relative;
  padding-right: 45px;
}
@media (max-width: 1200px) {
  .component-posts-grid__post-button[target=_blank] {
    padding-right: 40px;
  }
}
@media (max-width: 1000px) {
  .component-posts-grid__post-button[target=_blank] {
    padding-right: 35px;
  }
}
.component-posts-grid__post-button[target=_blank]:after {
  font-family: "Material Icons";
  content: "\e89e";
  position: absolute;
  right: 15px;
  font-size: 1.3rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1200px) {
  .component-posts-grid__post-button[target=_blank]:after {
    right: 12px;
  }
}
@media (max-width: 1000px) {
  .component-posts-grid__post-button[target=_blank]:after {
    font-size: 1.2rem;
  }
}
@media (max-width: 800px) {
  .component-posts-grid__post-button[target=_blank]:after {
    font-size: 1.1rem;
  }
}
.component-posts-grid__post-button:hover {
  background-color: #fff;
  color: var(--color-gray-text);
  border-color: #fff;
}
