html {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 14px;
  }
}
a {
  color: inherit;
}
h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
h1 {
  font-size: var(--font-size-h1);
  padding: 0 0 0.3em;
}
h2 {
  font-size: var(--font-size-h2);
  padding: 0 0 0.45em;
}
h3 {
  font-size: var(--font-size-h3-a);
  font-weight: 500;
  padding: 0 0 0.65em;
}
h4 {
  font-size: var(--font-size-h4-a);
  font-weight: 400;
  line-height: 1;
  padding: 0 0 0.45em;
}
h5 {
  font-weight: 500;
  font-size: var(--font-size-h5);
  padding: 0 0 0.45em;
}
p {
  padding: 0 0 1em;
}
.text--ingress {
  font-size: var(--font-size-ingress);
  line-height: 1.2;
  padding: 0 0 1.5em;
  max-width: 1250px;
}
@media (max-width: 1300px) {
  .text--ingress {
    line-height: 1.2;
  }
}
@media (max-width: 1000px) {
  .text--ingress {
    line-height: 1.3;
  }
}
@media (max-width: 700px) {
  .text--ingress {
    line-height: 1.4;
  }
}
.text--ingress[style*="text-align:center"],
.text--ingress[style*="text-align:center;"],
.text--ingress[style*="text-align: center"],
.text--ingress[style*="text-align: center;"] {
  margin: 0 auto;
}
.text--body-smaller {
  font-size: 14px;
  line-height: 20px;
}
.anchor-line-below {
  font-size: 1rem;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-blue-dark);
  text-decoration: none;
  padding: 0.4rem 0;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.component-columns--pattern .anchor-line-below,
.component-columns--color-blue-dark .anchor-line-below,
.component-columns__column--color-green-light .anchor-line-below {
  border-bottom-color: var(--color-white);
}
p[style*="text-align:center"] > .anchor-line-below,
p[style*="text-align:center;"] > .anchor-line-below,
p[style*="text-align: center"] > .anchor-line-below,
p[style*="text-align: center;"] > .anchor-line-below {
  padding: 0.4rem 0.8rem;
}
form input[type="text"],
form input[type="password"],
form input[type="email"],
form input[type="phone"] {
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  border-radius: 10rem;
  border: 1px solid;
}
@media (max-width: 1200px) {
  form input[type="text"],
  form input[type="password"],
  form input[type="email"],
  form input[type="phone"] {
    height: 38px;
    line-height: 37px;
  }
}
@media (max-width: 1000px) {
  form input[type="text"],
  form input[type="password"],
  form input[type="email"],
  form input[type="phone"] {
    height: 35px;
    line-height: 34px;
    padding: 0 18px;
  }
}
@media (max-width: 800px) {
  form input[type="text"],
  form input[type="password"],
  form input[type="email"],
  form input[type="phone"] {
    height: 32px;
    line-height: 30px;
    padding: 0 16px;
  }
}
form input[type="submit"],
form input[type="button"],
form button {
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  border-radius: 10rem;
  border: 1px solid;
  background: none;
  cursor: pointer;
}
@media (max-width: 1200px) {
  form input[type="submit"],
  form input[type="button"],
  form button {
    height: 38px;
    line-height: 37px;
  }
}
@media (max-width: 1000px) {
  form input[type="submit"],
  form input[type="button"],
  form button {
    height: 35px;
    line-height: 34px;
    padding: 0 18px;
  }
}
@media (max-width: 800px) {
  form input[type="submit"],
  form input[type="button"],
  form button {
    height: 32px;
    line-height: 30px;
    padding: 0 16px;
  }
}
form input[type="submit"][target=_blank],
form input[type="button"][target=_blank],
form button[target=_blank] {
  position: relative;
  padding-right: 45px;
}
@media (max-width: 1200px) {
  form input[type="submit"][target=_blank],
  form input[type="button"][target=_blank],
  form button[target=_blank] {
    padding-right: 40px;
  }
}
@media (max-width: 1000px) {
  form input[type="submit"][target=_blank],
  form input[type="button"][target=_blank],
  form button[target=_blank] {
    padding-right: 35px;
  }
}
form input[type="submit"][target=_blank]:after,
form input[type="button"][target=_blank]:after,
form button[target=_blank]:after {
  font-family: "Material Icons";
  content: "\e89e";
  position: absolute;
  right: 15px;
  font-size: 1.3rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1200px) {
  form input[type="submit"][target=_blank]:after,
  form input[type="button"][target=_blank]:after,
  form button[target=_blank]:after {
    right: 12px;
  }
}
@media (max-width: 1000px) {
  form input[type="submit"][target=_blank]:after,
  form input[type="button"][target=_blank]:after,
  form button[target=_blank]:after {
    font-size: 1.2rem;
  }
}
@media (max-width: 800px) {
  form input[type="submit"][target=_blank]:after,
  form input[type="button"][target=_blank]:after,
  form button[target=_blank]:after {
    font-size: 1.1rem;
  }
}
