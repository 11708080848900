.component-free-text {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (max-width: 1200px) {
  .component-free-text {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
  }
}
@media (max-width: 1000px) {
  .component-free-text {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
}
@media (max-width: 800px) {
  .component-free-text {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }
}
.component-free-text--text-color-white {
  color: #fff;
}
.component-columns__column .component-free-text,
.component-free-text--disable-padding {
  padding: 0;
}
.component-free-text--disable-padding p:last-child {
  padding: 0;
}
.component-free-text--font-size-ingress p {
  font-size: var(--font-size-ingress);
  line-height: 1.2;
  padding: 0 0 1.5em;
  max-width: 1250px;
}
@media (max-width: 1300px) {
  .component-free-text--font-size-ingress p {
    line-height: 1.2;
  }
}
@media (max-width: 1000px) {
  .component-free-text--font-size-ingress p {
    line-height: 1.3;
  }
}
@media (max-width: 700px) {
  .component-free-text--font-size-ingress p {
    line-height: 1.4;
  }
}
.component-free-text--font-size-ingress p[style*="text-align:center"],
.component-free-text--font-size-ingress p[style*="text-align:center;"],
.component-free-text--font-size-ingress p[style*="text-align: center"],
.component-free-text--font-size-ingress p[style*="text-align: center;"] {
  margin: 0 auto;
}
.component-free-text h3 {
  font-size: var(--font-size-h3-b);
  font-weight: 600;
}
.component-free-text p,
.component-free-text ul,
.component-free-text ol {
  font-family: var(--free-text-body-text-font), sans-serif;
}
.component-free-text__content {
  columns: var(--free-text-columns);
  column-width: 210px;
}
