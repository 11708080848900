.component-search-results #searchform {
  padding: 0 0 3rem;
}
.component-search-results #searchform > div {
  gap: var(--gap-size-medium);
  display: flex;
  align-items: center;
  justify-content: center;
}
.component-search-results #searchform input[type="text"] {
  min-width: min(380px, 30%);
  border-color: var(--color-blue-dark);
}
.component-search-results #searchform input[type="submit"] {
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  border-radius: 10rem;
  border: 1px solid;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 4px;
  color: var(--color-blue-dark);
  min-width: min(120px, 15%);
  text-transform: none;
}
@media (max-width: 1200px) {
  .component-search-results #searchform input[type="submit"] {
    height: 38px;
    line-height: 37px;
  }
}
@media (max-width: 1000px) {
  .component-search-results #searchform input[type="submit"] {
    height: 35px;
    line-height: 34px;
    padding: 0 18px;
  }
}
@media (max-width: 800px) {
  .component-search-results #searchform input[type="submit"] {
    height: 32px;
    line-height: 30px;
    padding: 0 16px;
  }
}
.component-search-results #searchform input[type="submit"][target=_blank] {
  position: relative;
  padding-right: 45px;
}
@media (max-width: 1200px) {
  .component-search-results #searchform input[type="submit"][target=_blank] {
    padding-right: 40px;
  }
}
@media (max-width: 1000px) {
  .component-search-results #searchform input[type="submit"][target=_blank] {
    padding-right: 35px;
  }
}
.component-search-results #searchform input[type="submit"][target=_blank]:after {
  font-family: "Material Icons";
  content: "\e89e";
  position: absolute;
  right: 15px;
  font-size: 1.3rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1200px) {
  .component-search-results #searchform input[type="submit"][target=_blank]:after {
    right: 12px;
  }
}
@media (max-width: 1000px) {
  .component-search-results #searchform input[type="submit"][target=_blank]:after {
    font-size: 1.2rem;
  }
}
@media (max-width: 800px) {
  .component-search-results #searchform input[type="submit"][target=_blank]:after {
    font-size: 1.1rem;
  }
}
.component-search-results #searchform input[type="submit"]:hover {
  background-color: var(--color-blue-dark);
  color: #fff;
  border-color: var(--color-blue-dark);
}
