.component-posts {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 6rem;
  --search-results-gap: 2.8rem;
}
@media (max-width: 1200px) {
  .component-posts {
    padding-top: 7.2rem;
    padding-bottom: 5.4rem;
  }
}
@media (max-width: 1000px) {
  .component-posts {
    padding-top: 6.4rem;
    padding-bottom: 4.800000000000001rem;
  }
}
@media (max-width: 800px) {
  .component-posts {
    padding-top: 5.2rem;
    padding-bottom: 3.9rem;
  }
}
.component-posts__posts {
  display: flex;
  flex-wrap: wrap;
  gap: var(--search-results-gap);
  list-style: none;
}
.component-posts__post {
  min-width: 40%;
  max-width: calc(50% - (var(--search-results-gap) / 2));
  flex: 1;
}
@media (max-width: 800px) {
  .component-posts__post {
    min-width: 100%;
    max-width: 100%;
  }
}
