.single-post-template {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .single-post-template {
    padding-top: 3.6rem;
    padding-bottom: 0;
  }
}
@media (max-width: 1000px) {
  .single-post-template {
    padding-top: 3.2rem;
    padding-bottom: 0;
  }
}
@media (max-width: 800px) {
  .single-post-template {
    padding-top: 2.6rem;
    padding-bottom: 0;
  }
}
.single-post-template:last-child {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (max-width: 1200px) {
  .single-post-template:last-child {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
  }
}
@media (max-width: 1000px) {
  .single-post-template:last-child {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
}
@media (max-width: 800px) {
  .single-post-template:last-child {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }
}
.single-post-template__content {
  max-width: 1200px;
}
.single-post-template__content p,
.single-post-template__content ul,
.single-post-template__content ol {
  max-width: 120ch;
}
.single-post-template__content img {
  height: auto;
}
.single-post-template__image {
  margin-bottom: 2rem;
}
