.component-spacer {
  --spacer-responsive-modifier: 1;
  margin-top: calc(var(--spacer-size) * var(--spacer-multiplier) * var(--spacer-responsive-modifier));
}
@media (max-width: 600px) {
  .component-spacer {
    --spacer-size: var(--spacer-size-mobile) !important;
  }
}
@media (max-width: 1400px) {
  .component-spacer {
    --spacer-responsive-modifier: 0.9;
  }
}
@media (max-width: 1200px) {
  .component-spacer {
    --spacer-responsive-modifier: 0.8;
  }
}
@media (max-width: 1000px) {
  .component-spacer {
    --spacer-responsive-modifier: 0.75;
  }
}
@media (max-width: 800px) {
  .component-spacer {
    --spacer-responsive-modifier: 0.65;
  }
}
@media (max-width: 600px) {
  .component-spacer {
    --spacer-responsive-modifier: 0.55;
  }
}
.component-spacer + div {
  position: relative;
}
