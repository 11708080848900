.component-breadcrumbs {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  display: flex;
  list-style: none;
  padding: 1rem 0;
  font-size: var(--font-size-breadcrumbs);
  flex-wrap: wrap;
}
.component-breadcrumbs a {
  text-decoration: none;
}
.component-breadcrumbs__custom-breadcrumbs {
  padding-right: 0.2rem;
}
.component-breadcrumbs__custom-breadcrumbs:not(:last-child):after {
  content: "/";
  margin-left: 0.2rem;
}
