.component-search-results {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 6rem;
  --search-results-gap: 2.8rem;
}
@media (max-width: 1200px) {
  .component-search-results {
    padding-top: 0;
    padding-bottom: 5.4rem;
  }
}
@media (max-width: 1000px) {
  .component-search-results {
    padding-top: 0;
    padding-bottom: 4.800000000000001rem;
  }
}
@media (max-width: 800px) {
  .component-search-results {
    padding-top: 0;
    padding-bottom: 3.9rem;
  }
}
.component-search-results__posts {
  display: flex;
  flex-wrap: wrap;
  gap: var(--search-results-gap);
  list-style: none;
}
.component-search-results__post {
  min-width: 40%;
  max-width: calc(50% - (var(--search-results-gap) / 2));
  flex: 1;
}
@media (max-width: 800px) {
  .component-search-results__post {
    min-width: 100%;
    max-width: 100%;
  }
}
.component-search-results__posts {
  list-style: none;
  padding: 0;
}
