.footer {
  background-color: #00305a;
  color: var(--color-blue-light);
}
.footer img.aligncenter {
  margin: 0 auto;
}
.footer__columns {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 9.4rem 0 13.75rem;
}
@media (max-width: 1500px) {
  .footer__columns {
    padding: 7rem 0 11rem;
  }
}
@media (max-width: 1200px) {
  .footer__columns {
    padding: 4rem 0 8rem;
  }
}
@media (max-width: 600px) {
  .footer__columns {
    padding: 4rem 0;
  }
}
.footer__column {
  flex: var(--flex);
  font-weight: 700;
  letter-spacing: 0.12em;
  max-width: 450px;
}
@media (max-width: 1200px) {
  .footer__column {
    min-width: 50%;
    margin: 0 0 2rem;
  }
}
@media (max-width: 600px) {
  .footer__column {
    min-width: 100%;
  }
}
.footer__column img {
  max-width: 200px;
}
@media (max-width: 1200px) {
  .footer__column img {
    max-width: 180px;
  }
}
@media (max-width: 1000px) {
  .footer__column img {
    max-width: 160px;
  }
}
@media (max-width: 800px) {
  .footer__column img {
    max-width: 140px;
  }
}
@media (max-width: 600px) {
  .footer__column img {
    max-width: 120px;
  }
}
.footer__column a {
  text-decoration: none;
}
.footer__column--text-size-larger {
  font-size: var(--font-size-h5);
  line-height: 1.2;
}
.footer__column--button a {
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  border-radius: 10rem;
  border: 1px solid;
  color: #fff;
  border-color: var(--color-blue-light);
  letter-spacing: 0;
}
@media (max-width: 1200px) {
  .footer__column--button a {
    height: 38px;
    line-height: 37px;
  }
}
@media (max-width: 1000px) {
  .footer__column--button a {
    height: 35px;
    line-height: 34px;
    padding: 0 18px;
  }
}
@media (max-width: 800px) {
  .footer__column--button a {
    height: 32px;
    line-height: 30px;
    padding: 0 16px;
  }
}
.footer__column--button a[target=_blank] {
  position: relative;
  padding-right: 45px;
}
@media (max-width: 1200px) {
  .footer__column--button a[target=_blank] {
    padding-right: 40px;
  }
}
@media (max-width: 1000px) {
  .footer__column--button a[target=_blank] {
    padding-right: 35px;
  }
}
.footer__column--button a[target=_blank]:after {
  font-family: "Material Icons";
  content: "\e89e";
  position: absolute;
  right: 15px;
  font-size: 1.3rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1200px) {
  .footer__column--button a[target=_blank]:after {
    right: 12px;
  }
}
@media (max-width: 1000px) {
  .footer__column--button a[target=_blank]:after {
    font-size: 1.2rem;
  }
}
@media (max-width: 800px) {
  .footer__column--button a[target=_blank]:after {
    font-size: 1.1rem;
  }
}
