.component-hero {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 210px;
  padding-bottom: 8rem;
  color: #fff;
  background-color: var(--hero-background-color);
  background-size: cover;
  background-position: center;
  position: relative;
  --no-padding-height-modifier: 0px;
  --fade-opacity: 0;
}
.component-hero--pattern {
  background-image: var(--pattern-hero-background-image);
  background-repeat: repeat;
  background-position: center;
  background-size: auto;
}
@media (max-width: 1400px) {
  .component-hero {
    padding-bottom: 7rem;
  }
}
@media (max-width: 1200px) {
  .component-hero {
    padding-bottom: 6rem;
  }
}
@media (max-width: 1000px) {
  .component-hero {
    padding-bottom: 5rem;
  }
}
@media (max-width: 800px) {
  .component-hero {
    padding-bottom: 4rem;
  }
}
.component-hero--no-padding-bottom {
  padding-bottom: 2rem;
  --no-padding-height-modifier: 10rem;
}
@media (max-width: 1400px) {
  .component-hero--no-padding-bottom {
    --no-padding-height-modifier: 9rem;
  }
}
@media (max-width: 1200px) {
  .component-hero--no-padding-bottom {
    --no-padding-height-modifier: 8rem;
  }
}
@media (max-width: 1000px) {
  .component-hero--no-padding-bottom {
    --no-padding-height-modifier: 7rem;
  }
}
@media (max-width: 800px) {
  .component-hero--no-padding-bottom {
    --no-padding-height-modifier: 6rem;
    padding-bottom: 0;
  }
}
.component-hero--has-content {
  min-height: calc(590px - var(--no-padding-height-modifier));
}
@media (max-width: 1400px) {
  .component-hero--has-content {
    min-height: calc(540px - var(--no-padding-height-modifier));
  }
}
@media (max-width: 1200px) {
  .component-hero--has-content {
    min-height: calc(480px - var(--no-padding-height-modifier));
  }
}
@media (max-width: 1000px) {
  .component-hero--has-content {
    min-height: calc(415px - var(--no-padding-height-modifier));
  }
}
@media (max-width: 700px) {
  .component-hero--has-content {
    min-height: calc(350px - var(--no-padding-height-modifier));
  }
}
.component-hero--compact {
  min-height: 0;
  height: 266px;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .component-hero--compact {
    height: 250px;
  }
}
@media (max-width: 1000px) {
  .component-hero--compact {
    height: 220px;
  }
}
@media (max-width: 700px) {
  .component-hero--compact {
    height: 180px;
  }
}
.component-hero__siblings {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem 5rem;
  list-style: none;
  padding: 5rem 0 0;
  font-size: 1.125rem;
  font-weight: 700;
}
@media (max-width: 1200px) {
  .component-hero__siblings {
    gap: 1rem 4rem;
  }
}
@media (max-width: 1000px) {
  .component-hero__siblings {
    gap: 1rem 3rem;
  }
}
@media (max-width: 800px) {
  .component-hero__siblings {
    gap: 1rem 2rem;
    padding: 1.5rem 0 0;
    overflow: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
.component-hero__siblings li {
  border-bottom: 2px solid transparent;
  padding-bottom: 0.2rem;
}
@media (max-width: 800px) {
  .component-hero__siblings li {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.component-hero__siblings a {
  text-decoration: none;
}
.component-hero__siblings .current_page_item {
  border-bottom: 2px solid #fff;
}
.component-hero--compact li {
  border-bottom: 10px solid transparent;
  padding-bottom: 1rem;
}
.component-hero--compact li.current_page_item {
  border-bottom: 10px solid #fff;
}
.component-hero__fader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: var(--fade-opacity);
  z-index: 0;
  mix-blend-mode: multiply;
}
.component-hero__content {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  z-index: 1;
}
@media (max-width: 800px) {
  .component-hero__content {
    max-width: 100%;
  }
}
.component-hero__content p {
  letter-spacing: 0.6px;
  line-height: 1.25;
  font-size: var(--font-size-ingress);
  max-width: 840px;
}
.component-hero__content p[style*="text-align:center"],
.component-hero__content p[style*="text-align:center;"],
.component-hero__content p[style*="text-align: center"],
.component-hero__content p[style*="text-align: center;"] {
  margin: 0 auto;
}
.component-hero__content h1 {
  padding-bottom: 1em;
}
.component-hero__content h1:last-child {
  padding-bottom: 0;
}
.component-hero__content-inner {
  max-width: calc(100% - 2rem);
  margin: 0 auto;
}
.component-hero__down-arrow:after {
  font-family: "Material Icons";
  content: "\e5cf";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4rem;
  font-size: 5.5rem;
  line-height: 1;
}
@media (max-width: 1200px) {
  .component-hero__down-arrow:after {
    bottom: 3rem;
    font-size: 5rem;
  }
}
@media (max-width: 1000px) {
  .component-hero__down-arrow:after {
    bottom: 2rem;
    font-size: 4.5rem;
  }
}
@media (max-width: 700px) {
  .component-hero__down-arrow:after {
    bottom: 1.5rem;
    font-size: 4rem;
  }
}
