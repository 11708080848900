.component-hero-in-page {
  --btm-margin: 6.5rem;
  background-size: cover;
  background-position: center;
  color: #fff;
}
@media (max-width: 750px) {
  .component-hero-in-page {
    background-image: none !important;
  }
}
@media (min-width: 750px) {
  .component-hero-in-page {
    margin-bottom: calc(var(--btm-margin) * 2);
  }
  .component-hero-in-page--vertical-top {
    transform: translateY(calc(var(--btm-margin) * 2));
  }
}
@media (min-width: 750px) {
  .component-hero-in-page__inner {
    position: relative;
    max-width: min(var(--component-width-max), calc(95vw - 3rem));
    margin: 0 auto;
    min-height: 780px;
  }
}
.component-hero-in-page__image-mobile {
  height: min(75vw, calc(100vh - 2rem));
  background-size: cover;
}
@media (min-width: 750px) {
  .component-hero-in-page__image-mobile {
    display: none;
  }
}
.component-hero-in-page__content-boxes-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  --translate-X: 0;
  --translate-Y: 0;
}
@media (min-width: 750px) {
  .component-hero-in-page__content-boxes-wrapper {
    flex-wrap: nowrap;
    position: absolute;
    transform: translateX(var(--translate-X)) translateY(var(--translate-Y));
    justify-content: flex-start;
    gap: var(--gap-size-xlarge);
  }
  .component-hero-in-page__content-boxes-wrapper--horizonal-center {
    justify-content: center;
  }
  .component-hero-in-page__content-boxes-wrapper--horizonal-right {
    justify-content: flex-end;
  }
  .component-hero-in-page__content-boxes-wrapper--vertical-bottom {
    bottom: 0;
    --translate-Y: var(--btm-margin);
  }
  .component-hero-in-page__content-boxes-wrapper--vertical-top {
    top: 0;
    --translate-Y: calc(var(--btm-margin) * -1);
  }
}
.component-hero-in-page__content {
  padding: 4.4rem 7.8rem;
  padding: 4.4rem 7.8rem;
  max-width: 864px;
  min-width: 42%;
  flex: 1;
}
@media (max-width: 1200px) {
  .component-hero-in-page__content {
    padding: 4rem 6rem;
    max-width: 750px;
  }
}
@media (max-width: 1000px) {
  .component-hero-in-page__content {
    padding: 3.5rem;
  }
}
@media (max-width: 900px) {
  .component-hero-in-page__content {
    padding: 2.5rem;
    max-width: 700px;
  }
}
@media (max-width: 750px) {
  .component-hero-in-page__content {
    position: relative;
    padding: 2rem;
    min-width: 100%;
  }
}
.component-hero-in-page__content .anchor-line-below {
  border-color: #fff;
}
