.component-news-posts {
  background: var(--color-gray-light);
}
.component-news-posts__inner {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (max-width: 1200px) {
  .component-news-posts__inner {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
  }
}
@media (max-width: 1000px) {
  .component-news-posts__inner {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
}
@media (max-width: 800px) {
  .component-news-posts__inner {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }
}
.component-news-posts__content-above {
  padding-bottom: 2rem;
}
.component-news-posts__posts {
  display: flex;
  gap: var(--gap-size-xlarge);
  justify-content: center;
  flex-wrap: wrap;
}
.component-news-posts__post {
  flex: 1;
  background: var(--color-white);
  max-width: calc(100% / 3);
  min-width: min(320px, 50%);
}
@media (max-width: 650px) {
  .component-news-posts__post {
    max-width: 100%;
  }
}
.component-news-posts__post img {
  height: 276px;
  width: 100%;
  object-fit: cover;
}
.component-news-posts__post h3 {
  font-size: var(--font-size-h3-c);
  line-height: 1.1;
  font-weight: 700;
  padding-right: 2rem;
}
.component-news-posts__post h3 a {
  text-decoration: none;
}
.component-news-posts__post-content {
  padding: var(--gap-size-large) var(--gap-size-large) calc(var(--gap-size-large) * 2);
}
.component-news-posts__post-date {
  font-weight: 600;
  color: var(--color-blue-dark);
}
.component-news-posts__see-all-link {
  text-align: center;
  margin: 4rem;
}
.component-news-posts__see-all-link a {
  font-size: 1rem;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-blue-dark);
  text-decoration: none;
  padding: 0.4rem 0;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-color: var(--color-blue-dark);
}
.component-columns--pattern .component-news-posts__see-all-link a,
.component-columns--color-blue-dark .component-news-posts__see-all-link a,
.component-columns__column--color-green-light .component-news-posts__see-all-link a {
  border-bottom-color: var(--color-white);
}
p[style*="text-align:center"] > .component-news-posts__see-all-link a,
p[style*="text-align:center;"] > .component-news-posts__see-all-link a,
p[style*="text-align: center"] > .component-news-posts__see-all-link a,
p[style*="text-align: center;"] > .component-news-posts__see-all-link a {
  padding: 0.4rem 0.8rem;
}
