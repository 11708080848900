.post-box {
  padding: 0 0 3rem;
}
@media (max-width: 1000px) {
  .post-box {
    padding-bottom: 2rem;
  }
}
@media (max-width: 700px) {
  .post-box {
    padding-bottom: 1rem;
  }
}
.post-box__headline {
  font-size: var(--font-size-h3-b);
  font-weight: 700;
}
.post-box__headline a {
  text-decoration: none;
}
.post-box__image {
  margin-bottom: 3.5rem;
}
@media (max-width: 1200px) {
  .post-box__image {
    margin-bottom: 2.8rem;
  }
}
@media (max-width: 800px) {
  .post-box__image {
    margin-bottom: 2rem;
  }
}
.post-box__image img {
  background: var(--color-gray-light-mid);
  height: 390px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 1200px) {
  .post-box__image img {
    height: 360px;
  }
}
@media (max-width: 1000px) {
  .post-box__image img {
    height: 320px;
  }
}
@media (max-width: 800px) {
  .post-box__image img {
    height: 280px;
  }
}
@media (max-width: 600px) {
  .post-box__image img {
    height: 240px;
  }
}
.post-box__content p:not(:first-child):last-child a {
  font-size: 1rem;
  border-bottom: 1px solid;
  border-bottom-color: var(--color-blue-dark);
  text-decoration: none;
  padding: 0.4rem 0;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.component-columns--pattern .post-box__content p:not(:first-child):last-child a,
.component-columns--color-blue-dark .post-box__content p:not(:first-child):last-child a,
.component-columns__column--color-green-light .post-box__content p:not(:first-child):last-child a {
  border-bottom-color: var(--color-white);
}
p[style*="text-align:center"] > .post-box__content p:not(:first-child):last-child a,
p[style*="text-align:center;"] > .post-box__content p:not(:first-child):last-child a,
p[style*="text-align: center"] > .post-box__content p:not(:first-child):last-child a,
p[style*="text-align: center;"] > .post-box__content p:not(:first-child):last-child a {
  padding: 0.4rem 0.8rem;
}
