:root {
  --gap-size-small: 1rem;
  --gap-size-medium: 1.5rem;
  --gap-size-large: 2rem;
  --gap-size-xlarge: 3rem;
  --column-width-full: 100%;
  --column-width-half: 50%;
  --column-width-two-thirds: calc(100% / 3 * 2);
  --column-width-one-third: calc(100% / 3 * 1);
  --component-width-max: 1510px;
  --color-red-dark: #98485b;
  --color-red-light: #b46b81;
  --color-green-dark: #004e42;
  --color-green-light: #6fa287;
  --color-blue-dark: #003c71;
  --color-blue-light: #6cace4;
  --color-yellow: #ffbf3f;
  --color-gray-light: #f7f6f4;
  --color-gray-light-mid: #e6e6e6;
  --color-white: #fff;
  --color-gray-text: #212121;
  --color-black-divider: #707070;
  --color-white-divider: var(--color-white);
  --font-size-h1: 60px;
  --font-size-h2: 46px;
  --font-size-h3-a: var(--font-size-h2);
  --font-size-h3-b: 34px;
  --font-size-h3-c: 26px;
  --font-size-h4-a: 24px;
  --font-size-h4-b: 30px;
  --font-size-h5: 20px;
  --font-size-breadcrumbs: 18px;
  --font-size-ingress: var(--font-size-h4-a);
}
@media (max-width: 1300px) {
  :root {
    --font-size-h1: 50px;
  }
}
@media (max-width: 1000px) {
  :root {
    --font-size-h1: 40px;
  }
}
@media (max-width: 810px) {
  :root {
    --font-size-h1: 30px;
  }
}
@media (max-width: 1300px) {
  :root {
    --font-size-h2: 40px;
  }
}
@media (max-width: 1000px) {
  :root {
    --font-size-h2: 36px;
  }
}
@media (max-width: 810px) {
  :root {
    --font-size-h2: 32px;
  }
}
@media (max-width: 600px) {
  :root {
    --font-size-h2: 28px;
  }
}
@media (max-width: 1300px) {
  :root {
    --font-size-h3-b: 32px;
  }
}
@media (max-width: 1000px) {
  :root {
    --font-size-h3-b: 28px;
  }
}
@media (max-width: 810px) {
  :root {
    --font-size-h3-b: 24px;
  }
}
@media (max-width: 1300px) {
  :root {
    --font-size-h3-c: 25px;
  }
}
@media (max-width: 1000px) {
  :root {
    --font-size-h3-c: 23px;
  }
}
@media (max-width: 810px) {
  :root {
    --font-size-h3-c: 20px;
  }
}
@media (max-width: 1300px) {
  :root {
    --font-size-h4-a: 22px;
  }
}
@media (max-width: 1000px) {
  :root {
    --font-size-h4-a: 20px;
  }
}
@media (max-width: 810px) {
  :root {
    --font-size-h4-a: 18px;
  }
}
@media (max-width: 1300px) {
  :root {
    --font-size-h4-b: 28px;
  }
}
@media (max-width: 1000px) {
  :root {
    --font-size-h4-b: 25px;
  }
}
@media (max-width: 810px) {
  :root {
    --font-size-h4-b: 22px;
  }
}
@media (max-width: 1250px) {
  :root {
    --font-size-h5: 18px;
  }
}
@media (max-width: 900px) {
  :root {
    --font-size-h5: 16px;
  }
}
@media (max-width: 1250px) {
  :root {
    --font-size-breadcrumbs: 16px;
  }
}
@media (max-width: 900px) {
  :root {
    --font-size-breadcrumbs: 15px;
  }
}
