.component-columns {
  background-repeat: repeat;
  color: var(--text-color);
  background-color: var(--background-color);
}
.component-columns__columns-wrapper {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 5.5rem;
  padding-bottom: 5rem;
  display: flex;
  margin: 0 auto;
  gap: var(--gap-size);
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .component-columns__columns-wrapper {
    padding-top: 4.95rem;
    padding-bottom: 4.5rem;
  }
}
@media (max-width: 1000px) {
  .component-columns__columns-wrapper {
    padding-top: 4.4rem;
    padding-bottom: 4rem;
  }
}
@media (max-width: 800px) {
  .component-columns__columns-wrapper {
    padding-top: 3.575rem;
    padding-bottom: 3.25rem;
  }
}
.component-columns__column {
  --with-background-padding: 2.6rem 2.6rem 1.6rem;
  --no-background-padding: 0;
  flex: 1;
  background-color: var(--column-background-color);
  padding: var(--column-padding);
  max-width: var(--column-width);
  min-width: min(100%, 345px);
}
