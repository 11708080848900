.component-news-page {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (max-width: 1200px) {
  .component-news-page {
    padding-top: 2.7rem;
    padding-bottom: 2.7rem;
  }
}
@media (max-width: 1000px) {
  .component-news-page {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
}
@media (max-width: 800px) {
  .component-news-page {
    padding-top: 1.95rem;
    padding-bottom: 1.95rem;
  }
}
.component-news-page__categories {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  list-style: none;
  display: flex;
  gap: 1rem 5rem;
  flex-wrap: wrap;
  padding: 0 0 2rem;
  font-weight: 700;
  font-family: "Montserrat";
  font-size: var(--font-size-ingress);
}
@media (max-width: 450px) {
  .component-news-page__categories {
    flex-direction: column;
    align-items: flex-start;
  }
}
.component-news-page__categories a {
  text-decoration: none;
}
.component-news-page__category--current {
  border-bottom: 5px solid var(--color-blue-light);
  color: var(--color-blue-dark);
}
.component-news-page__filter {
  background: var(--color-gray-light-mid);
}
.component-news-page__filter-explanation {
  margin-right: 2.5rem;
}
@media (max-width: 550px) {
  .component-news-page__filter-explanation {
    min-width: 100%;
  }
}
.component-news-page__filter-title-message {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 0;
  font-size: var(--font-size-h5);
}
@media (max-width: 1200px) {
  .component-news-page__filter-title-message {
    padding-top: 1.8rem;
    padding-bottom: 0;
  }
}
@media (max-width: 1000px) {
  .component-news-page__filter-title-message {
    padding-top: 1.6rem;
    padding-bottom: 0;
  }
}
@media (max-width: 800px) {
  .component-news-page__filter-title-message {
    padding-top: 1.3rem;
    padding-bottom: 0;
  }
}
.component-news-page__filter-title-message a {
  font-weight: 700;
  text-decoration: none;
  margin-left: 1rem;
}
.component-news-page__filter-title-button {
  background: var(--color-blue-dark);
  color: var(--color-white);
  border-radius: 4px;
  height: 50px;
  line-height: 50px;
  padding: 0 1.25rem;
  display: inline-block;
}
.component-news-page__filter-inner {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  padding: 2rem 0;
  font-family: "Montserrat";
  font-size: var(--font-size-ingress);
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.component-news-page__filter-inner .select-wrapper {
  position: relative;
}
@media (max-width: 400px) {
  .component-news-page__filter-inner .select-wrapper,
  .component-news-page__filter-inner .select-wrapper select {
    width: 100%;
  }
}
.component-news-page__filter-inner .select-wrapper:after {
  font-family: "Material Icons";
  content: "\e5cf";
  position: absolute;
  right: 10px;
  top: 50%;
  color: var(--color-white);
  transform: translateY(-50%);
  pointer-events: none;
}
.component-news-page__filter-inner .select-wrapper select {
  min-width: 110px;
  font-family: "Source Sans Pro";
  background: var(--color-blue-light);
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: var(--font-size-h5);
  color: #fff;
  border-radius: 4px;
  height: 50px;
  padding: 0 2rem 0 1.25rem;
  font-weight: 400;
}
.component-news-page__main {
  padding-top: 5rem;
  padding-bottom: 2rem;
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .component-news-page__main {
    padding-top: 4.5rem;
    padding-bottom: 1.8rem;
  }
}
@media (max-width: 1000px) {
  .component-news-page__main {
    padding-top: 4rem;
    padding-bottom: 1.6rem;
  }
}
@media (max-width: 800px) {
  .component-news-page__main {
    padding-top: 3.25rem;
    padding-bottom: 1.3rem;
  }
}
.component-news-page__posts {
  flex: 2;
  min-width: min(500px, 90%);
}
.component-news-page__posts .post-box__headline {
  font-size: var(--font-size-h2);
}
.component-news-page__posts .post-box__headline a {
  color: var(--color-blue-light);
}
.component-news-page__posts .post-box__image {
  margin-bottom: 1.5rem;
}
.component-news-page__aside {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.component-news-page__aside .component-columns__column {
  flex: 0;
}
.component-news-page__aside h3 {
  font-size: var(--font-size-h3-b);
}
.component-news-page__post-date {
  margin-right: 0.5rem;
}
.component-news-page__post-meta {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 1.5rem;
}
.component-news-page__post-meta a {
  text-decoration: none;
  color: var(--color-blue-light);
}
