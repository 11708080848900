body.mobile-menu-is-open {
  overflow: hidden;
  height: 100%;
}
.header {
  position: absolute;
  width: 100%;
  color: #fff;
  z-index: 2;
}
.header__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem var(--gap-size-xlarge);
}
@media (max-width: 800px) {
  .header__inner {
    padding: var(--gap-size-medium);
  }
}
.header__logo img {
  width: 330px;
}
@media (max-width: 1500px) {
  .header__logo img {
    width: 290px;
  }
}
@media (max-width: 1200px) {
  .header__logo img {
    width: 270px;
  }
}
@media (max-width: 1000px) {
  .header__logo img {
    width: 230px;
  }
}
@media (max-width: 800px) {
  .header__logo img {
    width: 210px;
  }
}
@media (max-width: 600px) {
  .header__logo img {
    width: 180px;
  }
}
@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
