.component-quote {
  padding-top: 5rem;
  padding-bottom: 6rem;
}
@media (max-width: 1200px) {
  .component-quote {
    padding-top: 4.5rem;
    padding-bottom: 5.4rem;
  }
}
@media (max-width: 1000px) {
  .component-quote {
    padding-top: 4rem;
    padding-bottom: 4.800000000000001rem;
  }
}
@media (max-width: 800px) {
  .component-quote {
    padding-top: 3.25rem;
    padding-bottom: 3.9rem;
  }
}
.component-quote__inner {
  max-width: min(var(--component-width-max), calc(95vw - 3rem));
  margin: 0 auto;
  font-size: var(--font-size-ingress);
  line-height: 1.2;
  padding: 0 0 1.5em;
  max-width: 1250px;
  letter-spacing: 0.6px;
  text-align: center;
  padding: 7rem 3rem 0.5rem;
  background-color: var(--background-color);
  color: #fff;
  position: relative;
  width: 95%;
}
@media (max-width: 1300px) {
  .component-quote__inner {
    line-height: 1.2;
  }
}
@media (max-width: 1000px) {
  .component-quote__inner {
    line-height: 1.3;
  }
}
@media (max-width: 700px) {
  .component-quote__inner {
    line-height: 1.4;
  }
}
.component-quote__inner[style*="text-align:center"],
.component-quote__inner[style*="text-align:center;"],
.component-quote__inner[style*="text-align: center"],
.component-quote__inner[style*="text-align: center;"] {
  margin: 0 auto;
}
@media (max-width: 1000px) {
  .component-quote__inner {
    padding: 6rem 2.5rem 0.5rem;
  }
}
@media (max-width: 700px) {
  .component-quote__inner {
    padding: 5rem 2rem 0.5rem;
  }
}
.component-quote__inner:before {
  content: "”";
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12rem;
  line-height: 1;
}
@media (max-width: 1000px) {
  .component-quote__inner:before {
    font-size: 10rem;
  }
}
@media (max-width: 700px) {
  .component-quote__inner:before {
    font-size: 8rem;
  }
}
.component-quote__inner:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-100%);
  background-color: inherit;
  height: 50px;
  width: 50px;
  clip-path: polygon(0% 0%, 0% 100%, 90% 0%);
}
@media (max-width: 1000px) {
  .component-quote__inner:after {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 700px) {
  .component-quote__inner:after {
    height: 30px;
    width: 30px;
  }
}
.component-quote__quote {
  font-style: italic;
  max-width: 850px;
  margin: 0 auto;
  line-height: 1;
}
.component-quote__person {
  color: var(--accent-color);
  font-weight: 700;
}
