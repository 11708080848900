.component-contact-lines {
  font-size: var(--font-size-h5);
}
.component-contact-lines__content-above {
  font-size: var(--font-size-h3-c);
  line-height: 1.3;
}
.component-contact-lines__content-above p {
  padding: 0 0 0.8rem;
}
.component-contact-lines__lines-wrapper {
  padding: 0 0 0.5rem;
}
.component-contact-lines__line {
  display: flex;
  padding: 0 0 0.5rem;
}
.component-contact-lines__icon {
  width: 2.3rem;
}
.component-contact-lines__icon:before {
  font-family: "Material Icons";
  content: var(--icon-content);
  padding: 0 0.2rem;
}
